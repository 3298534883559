import React from "react";
import theme from "theme";
import { Theme, Text, Hr, Image, Box, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Наші послуги в Зелених Просторах
			</title>
			<meta name={"description"} content={"Зробіть ваш сад місцем мрії – відкрийте повний спектр наших садових послуг"} />
			<meta property={"og:title"} content={"Наші послуги в Зелених Просторах"} />
			<meta property={"og:description"} content={"Зробіть ваш сад місцем мрії – відкрийте повний спектр наших садових послуг"} />
			<meta property={"og:image"} content={"https://sichheritage.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://sichheritage.com/img/landscape-logo.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://sichheritage.com/img/landscape-logo.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://sichheritage.com/img/landscape-logo.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://sichheritage.com/img/landscape-logo.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://sichheritage.com/img/landscape-logo.png"} />
			<meta name={"msapplication-TileImage"} content={"https://sichheritage.com/img/landscape-logo.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 90px 0" quarkly-title="Product-6">
			<Override slot="SectionContent" lg-align-items="center" />
			<Text
				margin="0px 0px 20px 0px"
				font="normal 500 56px/1.2 --fontFamily-sans"
				text-align="center"
				lg-width="70%"
				md-width="100%"
				color="--darkL1"
			>
				Основні послуги
			</Text>
			<Text
				margin="0px 0px 20px 0px"
				text-align="center"
				font="normal 400 22px/1.5 --fontFamily-sansHelvetica"
				color="--darkL1"
				padding="0px 180px 0px 180px"
				lg-padding="0px 0 0px 0"
				lg-margin="0px 0px 50px 0px"
			>
				У Зелених Просторах ми пропонуємо широкий спектр послуг, спрямованих на те, щоб ваш сад був здоровим, красивим та добре доглянутим. Від косіння газонів до детального догляду за рослинами – ми ваш ключ до ідеального зеленого простору.
			</Text>
			<Hr
				min-height="10px"
				margin="0px 0px 50px 0px"
				border-color="--color-darkL1"
				width="40px"
				border-width="2px 0 0 0"
				align-self="center"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				md-margin="0px 0px 50px 0px"
				grid-gap="0 35px"
				lg-grid-gap="16px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					background="#d9e9fa"
					border-radius="4px"
				>
					<Image
						src="https://sichheritage.com/img/2.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						display="flex"
						flex-direction="column"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Догляд за газоном
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							• Регулярне косіння: Забезпечуйте своєму газону ідеальний вигляд з нашими послугами регулярного косіння.
							<br />
							• Аерація та внесення добрив: Покращуйте здоров'я та родючість вашого газону завдяки професійній аерації та внесенню добрив.
							<br />
							• Контроль за бур'янами: Захистіть ваш газон від шкідливих бур'янів з нашими ефективними методами боротьби.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://sichheritage.com/img/3.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Догляд за рослинами та квітами
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							• Обрізка та формування: Зберігайте здоровий та охайний вигляд ваших рослин і квітів з нашими послугами обрізки.
							<br />
							<br />
							• Підживлення та захист: Забезпечуйте своїм рослинам все необхідне для їх зростання та цвітіння.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					background="#d9e9fa"
					border-radius="4px"
					display="flex"
					flex-direction="column"
				>
					<Image
						src="https://sichheritage.com/img/4.jpg"
						display="block"
						max-width="100%"
						height="300px"
						width="100%"
						object-fit="cover"
						sm-height="220px"
						lg-height="200px"
						md-height="350px"
						border-radius="4px 4px 0 0"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="30px 30px 30px 30px"
						border-color="--color-darkL1"
						border-radius="0 0 4px 4px"
						lg-padding="20px 20px 20px 20px"
						flex="1 1 0%"
						display="flex"
						flex-direction="column"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 20px/1.2 --fontFamily-sans">
							Сезонні послуги
						</Text>
						<Text margin="0px 0px 25px 0px" font="normal 300 16px/1.5 --fontFamily-serifGaramond" lg-margin="0px 0px 18px 0px" flex="1 1 0%">
							• Весняне оновлення: Дайте своєму саду ідеальний старт у новому сезоні з нашими весняними оновленнями.
							<br />
							<br />
							• Осіннє прибирання: Підготуйте ваш сад до зими з нашими послугами осіннього прибирання, включаючи збір листя та підготовку рослин.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="180px 0 180px 0" background="linear-gradient(0deg,rgba(0, 0, 0, 0.4) 0%,rgba(0,0,0,0.4) 100%),rgba(0, 0, 0, 0) url(https://sichheritage.com/img/1.jpg) 50% 50% /cover repeat scroll padding-box" md-padding="100px 0 100px 0" quarkly-title="Content-12">
			<Override
				slot="SectionContent"
				flex-direction="row"
				align-items="center"
				justify-content="center"
				md-flex-direction="column"
			/>
			<Box
				min-width="100px"
				min-height="100px"
				padding="0px 80px 0px 0px"
				lg-width="70%"
				md-width="100%"
				md-padding="0px 0 0px 0px"
				md-margin="0px 0px 50px 0px"
			>
				<Text margin="0px 0px 10px 0px" font="normal 500 20px/1.5 --fontFamily-sans" color="white">
					Зелені Простори
				</Text>
				<Text margin="0px 0px 30px 0px" font="--headline2" color="white" width="50%">
					Створіть свій ідеальний зелений світ
				</Text>
				<Text margin="0px 0px 40px 0px" color="--light" font="--base" width="50%">
					Приєднуйтесь до нас у подорожі до створення та підтримки вашого ідеального саду. Нехай кожна рослина та кожен квітник розповість свою унікальну історію, перетворюючи ваш простір на оазис краси та спокою.
				</Text>
				<Button
					font="normal 400 16px/1.5 --fontFamily-sans"
					href="/contacts"
					type="link"
					text-decoration-line="initial"
					background="--color-dark"
					padding="12px 28px 12px 28px"
				>
					Звʼязатися
				</Button>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});